import { History, InsertChart, Timer } from "@mui/icons-material";
import { GridLocaleText } from "@mui/x-data-grid";
import { ListElement } from "./types";

export const lightColor: string = "#be1634";
export const darkColor: string = "#063467";
export const greyColor: string = "#969696";

//TABLE TEXT
export const customLocaleText: (t: any) => Partial<GridLocaleText> = (
  t: any
) => ({
  columnMenuSortAsc: t("sortAsc"),
  columnMenuSortDesc: t("sortDesc"),
  columnMenuUnsort: t("unsort"),
  columnMenuFilter: t("filter"),
  columnMenuHideColumn: t("hideColumn"),
  columnMenuShowColumns: t("showColumns"),
  columnMenuManageColumns: t("manageColumns"),
  columnsPanelHideAllButton: t("hideAll"),
  columnsPanelShowAllButton: t("showAll"),
  columnsPanelTextFieldLabel: `${t("findColumn")}`,
  columnsPanelTextFieldPlaceholder: `${t("search")}`,
  filterPanelAddFilter: t("addFilter"),
  filterPanelInputLabel: `${t("search")}`,
  filterPanelInputPlaceholder: `${t("search")}`,
  filterPanelRemoveAll: t("removeAll"),
  filterPanelColumns: t("columns"),
  filterPanelOperator: t("operators"),
  filterOperatorIsEmpty: `${t("isEmpty")}`,
  filterOperatorContains: `${t("contains")}`,
  filterOperatorStartsWith: `${t("startsWith")}`,
  filterOperatorEndsWith: `${t("endsWith")}`,
  filterOperatorIsNotEmpty: `${t("isNotEmpty")}`,
  filterOperatorEquals: `${t("equals")}`,
  filterOperatorIsAnyOf: `${t("isAnyOf")}`,
});

//NAVBAR OPTIONS
export const homeOptions: (t: any) => ListElement[] = (t: any) => [
  {
    primaryText: t("dashboard"),
    onClick: () => (window.location.pathname = "/dashboard"),
    startIcon: <InsertChart sx={{ color: lightColor, fontSize: "40px" }} />,
  },
  {
    primaryText: t("dataHistory"),
    onClick: () => (window.location.pathname = "/dataHistory"),
    startIcon: <History sx={{ color: lightColor, fontSize: "40px" }} />,
  },
  {
    primaryText: t("alarmsHistory"),
    onClick: () => (window.location.pathname = "/alarms"),
    startIcon: <Timer sx={{ color: lightColor, fontSize: "40px" }} />,
  },
];

export const capitalizedString = (inputString: string) =>
  inputString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
