import { 
    AssignResourceRequest, 
    DeleteResourceRequest, 
    UpdateResourceRequest
  } from "../requests/resourceService";
  
  import {
    methodGet,
    methodPost,
    methodPut,
    verticalFetch,
  } from "./httpRequests";
  
  import {
    assignResourceUrl,
    getResourcesUrl,
    getResourcesToAssignUrl,
    updateResourceUrl,
    deleteResourceUrl,
    getResourceUrl,
    getAvailableResourcesUrl,
    getResourcesByDateAndShiftUrl
  } from "./url";
  
  export const getResources = ( oid: string ) => {
    return verticalFetch(methodGet, getResourcesUrl(oid));
  };

  export const getResource = ( id: string ) => {
    return verticalFetch(methodGet, getResourceUrl(id));
  };

  export const getResourcesToAssign = (oid: string | undefined) => {
    return verticalFetch(methodGet, getResourcesToAssignUrl(oid));
  };

  export const getAvailableResources = (oid: string, date: string, shift: string) => {
    return verticalFetch(methodGet, getAvailableResourcesUrl(oid, date, shift));
  };

  export const getResourcesByDateAndShift = (oid: string, date: string, shift: string) => {
    return verticalFetch(methodGet, getResourcesByDateAndShiftUrl(oid, date, shift));
  };
  
  export const assignResource = (body: AssignResourceRequest) => {
    return verticalFetch(methodPost, assignResourceUrl, JSON.stringify(body));
  };

  export const updateResource = (oid: string, body: UpdateResourceRequest) => {
    return verticalFetch(methodPut, updateResourceUrl(oid), JSON.stringify(body));
  };

  export const deleteResource = (oid: string, body: DeleteResourceRequest) => {
    return verticalFetch(methodPut, deleteResourceUrl(oid), JSON.stringify(body));
  };