import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { assignedResourcesUrl, mainUrl, routes } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import {
  assignResource as assignResourceApi,
  getAvailableResources,
  getResourcesByDateAndShift,
} from "../../../api/services/resourceService";
import { useSnackbar } from "notistack";
import { AssignResourceRequest } from "../../../api/requests/resourceService";
import CustomLoading from "../../custom/CustomLoading";
import CustomSelect from "../../custom/CustomSelect";
import { getProfile } from "../../../api/services/userService";

type AssignResourcesProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const AssignResources: React.FC<AssignResourcesProps> = ({
  setBreadcrumb,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams(); //order uid
  const [cranes, setCranes] = useState<any[]>([]);
  const [craneOperators, setCraneOperators] = useState<any[]>([]);
  const [signalers, setSignalers] = useState<any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const [values, setValues] = useState({
    order_id: "",
    crane: 0,
    crane_operator: 0,
    shift: "",
    signaler: 0,
    controller: "",
    date_shift: "",
  });

  const [dateShift, setDateShift] = useState({
    date_shift: "",
    shift: "",
  });

  // Function to get the current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Function to get the current date minus one day in YYYY-MM-DD format
  const getCurrentDateMinusOneDay = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split("T")[0];
  };

  // useEffect to update the date_shift when the shift changes
  useEffect(() => {
    let newDateShift = "";
    if (
      dateShift.shift === "1" ||
      dateShift.shift === "2" ||
      dateShift.shift === "3"
    ) {
      newDateShift = getCurrentDate();
    } else if (dateShift.shift === "4") {
      newDateShift = getCurrentDateMinusOneDay();
    }

    setDateShift((prevState) => ({
      ...prevState,
      date_shift: newDateShift,
    }));
  }, [dateShift.shift]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    getProfile().then((res) => {
      if (res && res.user) {
        setValues({ ...values, controller: res.user.email });
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    if (dateShift.date_shift === "" || dateShift.shift === "") {
      enqueueSnackbar(t("fillDateShift"), { variant: "error" });
    } else {
      // getResourcesByDateAndShift(
      //   id!,
      //   dateShift.date_shift,
      //   dateShift.shift
      // ).then((res: any) => {
      //   //TODO: chiedere a francesco a cosa serve questa chiamata
      //   setLoading(false);
      // });
      getAvailableResources(id!, dateShift.date_shift, dateShift.shift).then(
        (res: any) => {
          if (res && res.resources) {
            if (
              res.resources.cranes == null &&
              res.resources.crane_operators == null &&
              res.resources.signalers == null
            ) {
              enqueueSnackbar(t("noResourcesAvailable"), { variant: "error" });
            } else {
              if (res.resources.orders && res.resources.orders.length > 0) {
                setOrders([...res.resources.orders]);
              }

              if (res.resources.cranes && res.resources.cranes.length > 0) {
                setCranes([...res.resources.cranes]);
              } else {
                setCranes([]);
              }

              if (
                res.resources.crane_operators &&
                res.resources.crane_operators.length > 0
              ) {
                setCraneOperators([...res.resources.crane_operators]);
              } else {
                setCraneOperators([]);
              }

              if (
                res.resources.signalers &&
                res.resources.signalers.length > 0
              ) {
                setSignalers([...res.resources.signalers]);
              } else {
                setSignalers([]);
              }
              setVisible(true);
            }
          } else {
            enqueueSnackbar(t("errorGettingResources"), { variant: "error" });
          }
        }
      );
    }
  };

  const handleSubmitAssign = () => {
    if (id) {
      values.order_id = id;
    }

    if (values.crane === 0 && values.crane_operator === 0) {
      enqueueSnackbar(t("fillAFields"), { variant: "error" });
    } else {
      assignResourceApi({
        order_id: values.order_id,
        crane: values.crane,
        crane_operator: values.crane_operator,
        shift: dateShift.shift,
        signaler: values.signaler,
        controller: values.controller,
        date_shift: dateShift.date_shift,
      } as AssignResourceRequest).then((res) => {
        if (res && res.resources) {
          setValues({
            order_id: "",
            crane: 0,
            crane_operator: 0,
            shift: "",
            signaler: 0,
            controller: "",
            date_shift: "",
          });
          enqueueSnackbar(t("resourcesAssignedSuccess"), {
            variant: "success",
          });
          navigate(assignedResourcesUrl(res.resources.order_id));
        } else {
          enqueueSnackbar(
            `${t("resourcesAssignedError")} ${res?.err?.message}`,
            {
              variant: "error",
            }
          );
        }
      });
    }
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        {id ? (
          <CustomTitle
            title={t(title) + " " + t("order") + " " + id}
            goBack={() => {
              navigate(routes.getAssignedResourcesUrl(id!));
            }}
          />
        ) : (
          <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
        )}
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          header={{
            visible: true,
            title: `${t("insertDateShift")}`,
          }}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("shift")}`}
                  value={dateShift.shift}
                  options={[
                    { label: t("shift1"), value: "1" },
                    { label: t("shift2"), value: "2" },
                    { label: t("shift3"), value: "3" },
                    { label: t("shift4"), value: "4" },
                  ]}
                  handleChange={(e) => {
                    setDateShift({ ...dateShift, shift: e.target.value });
                    setVisible(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("dateShift")}
                  type="date"
                  value={dateShift.date_shift}
                  handleChange={(e) => {
                    setDateShift({ ...dateShift, date_shift: e.target.value });
                    setVisible(false);
                  }}
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
      {visible ? (
        <Grid item xs={12} md={8}>
          <CustomCard
            header={{
              visible: true,
              title: `${t("assignAvailableResources")}`,
            }}
            content={
              <Grid container spacing={2}>
                {!id ? (
                  <Grid item xs={12} md={6}>
                    <CustomSelect
                      label={`${t("orderId")}`}
                      value={values.order_id}
                      options={orders.map((order) => ({
                        value: order.order_id,
                        label: order.order_id,
                      }))}
                      handleChange={(e) =>
                        setValues({ ...values, order_id: e.target.value })
                      }
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label={`${t("crane")}`}
                    value={values.crane as any}
                    options={cranes.map((crane) => ({
                      value: crane.code,
                      label: crane.description,
                    }))}
                    handleChange={(e) =>
                      setValues({ ...values, crane: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label={`${t("craneOperator")}`}
                    value={values.crane_operator as any}
                    options={craneOperators.map((craneOperator) => ({
                      value: craneOperator.code,
                      label: craneOperator.description,
                    }))}
                    handleChange={(e) =>
                      setValues({ ...values, crane_operator: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label={`${t("signaler")}`}
                    value={values.signaler as any}
                    options={signalers.map((signaler) => ({
                      value: signaler.code,
                      label: signaler.description,
                    }))}
                    handleChange={(e) =>
                      setValues({ ...values, signaler: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            }
            actions={[
              <CustomButton
                fullWidth
                label={t("reset")}
                onClick={() =>
                  setValues({
                    order_id: "",
                    crane: 0,
                    crane_operator: 0,
                    shift: "",
                    signaler: 0,
                    controller: "",
                    date_shift: "",
                  })
                }
                confirm
                type="outlined"
              />,
              <CustomButton
                fullWidth
                label={t("ok")}
                onClick={handleSubmitAssign}
                type="contained"
              />,
            ]}
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};
export default AssignResources;
