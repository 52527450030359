import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDashboardManager } from "../../../api/services/dashboardService";
import CustomLoading from "../../custom/CustomLoading";
import { darkColor } from "../../custom/utils";
import CustomText from "../../custom/CustomText";
import CustomList from "../../custom/CustomList";
import CustomCard from "../../custom/CustomCard";

type DashboardManagerMobileDetailsProps = {
  setNavbarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const DashboardManagerMobileDetails: React.FC<
  DashboardManagerMobileDetailsProps
> = ({ setNavbarCollapsed, setHeaderCollapsed }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const batch_id = searchParams.get("batch_id");
  const batch_status = searchParams.get("batch_status");
  const shift_id = searchParams.get("shift_id");
  const crane_id = searchParams.get("crane_id");
  const start = searchParams.get("start");
  const end = searchParams.get("end");

  useEffect(() => {
    setNavbarCollapsed(true);
    setHeaderCollapsed(true);
    return () => {
      setHeaderCollapsed(false);
    };
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [dashboard, setDashboardManager] = useState<any>({});
  useEffect(() => {
    getDashboardManager(
      batch_id?.split(" - ")[0] || "",
      batch_status!,
      shift_id || "",
      crane_id || "",
      start || "",
      end || ""
    ).then((res: any) => {
      if (res && res.dashboard) {
        setDashboardManager(
          [...res.dashboard].filter((el: any) => el.vessel === batch_id)[0]
        );
      } else {
        setDashboardManager({});
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "10px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={`${t("details")}`} goBack={() => navigate(-1)} />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          backgroundColor: darkColor,
          borderRadius: "10px",
          color: "white",
          padding: "0px",
          marginLeft: "16px",
        }}
        textAlign="center"
      >
        <CustomText label={dashboard?.vessel} type="subtitle1" />
      </Grid>

      <Grid item xs={12}>
        <CustomCard
          content={
            <Grid container textAlign="center">
              <Grid item xs={12}>
                <CustomText label={`${t("gmph")}`} type="h3" />
              </Grid>
              <Grid item xs={12}>
                <CustomText label={dashboard?.gmph} type="h2" />
              </Grid>
            </Grid>
          }
        />
      </Grid>

      <Grid item xs={12}>
        <CustomList
          dense
          listElement={[
            {
              primaryText: `${t("totalMoves")}`,
              secondaryText: dashboard?.total_movements || "N.A.",
            },
            {
              primaryText: `${t("toDo")}`,
              secondaryText: dashboard?.moves_to_do || "N.A.",
            },
            {
              primaryText: `${t("done")}`,
              secondaryText: dashboard?.moves_done || "N.A.",
            },
            {
              primaryText: `${t("percentage")}`,
              secondaryText: dashboard?.percentage + " %" || "N.A.",
            },
            {
              primaryText: `${t("firstLift")}`,
              secondaryText:
                new Date(dashboard?.vessel_fl)
                  .toISOString()
                  .replace("T", " ")
                  .split(".")[0] || "N.A.",
            },
            {
              primaryText: `${t("lastLift")}`,
              secondaryText:
                new Date(dashboard?.vessel_ll)
                  .toISOString()
                  .replace("T", " ")
                  .split(".")[0] || "N.A.",
            },
            {
              primaryText: `${t("workingTime")}`,
              secondaryText: dashboard?.working_time || "N.A.",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardManagerMobileDetails;
