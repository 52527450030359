import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  dashboardManagerUrl,
  getDashboardManagerResultDetailsUrl,
  getDashboardManagerResultGearUrl,
} from "../costants";
import { getDashboardManager } from "../../../api/services/dashboardService";
import CustomLoading from "../../custom/CustomLoading";
import CustomCard from "../../custom/CustomCard";
import CustomList from "../../custom/CustomList";
import CustomDivider from "../../custom/CustomDivider";
import { Handyman, Search } from "@mui/icons-material";
import CustomIconButton from "../../custom/CustomIconButton";
import { getResources } from "../../../api/services/resourceService";

type DashboardManagerMobileProps = {
  setNavbarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const DashboardManagerMobile: React.FC<DashboardManagerMobileProps> = ({
  setNavbarCollapsed,
  setHeaderCollapsed,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const batch_status = searchParams.get("batch_status");
  const batch_id = searchParams.get("batch_id");
  const shift_id = searchParams.get("shift_id");
  const crane_id = searchParams.get("crane_id");
  const start = searchParams.get("start");
  const end = searchParams.get("end");

  useEffect(() => {
    setNavbarCollapsed(true);
    setHeaderCollapsed(true);
    return () => {
      setHeaderCollapsed(false);
    };
  }, [batch_id, shift_id, crane_id, batch_status, start, end]);

  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [dashboard, setDashboardManager] = useState<any[]>([]);
  const [resourcesAvailability, setResourcesAvailability] = useState<{
    [vessel: string]: boolean;
  }>({});

  useEffect(() => {
    getDashboardManager(
      batch_id?.split(" - ")[0] || "",
      batch_status!,
      shift_id || "",
      crane_id || "",
      start || "",
      end || ""
    ).then((res: any) => {
      if (res && res.dashboard) {
        setDashboardManager([...res.dashboard]);
        checkResourcesAvailability(res.dashboard);
      } else {
        setDashboardManager([]);
      }
      setLoading(false);
    });

    const intervalId = setInterval(() => {
      getDashboardManager(
        batch_id?.split(" - ")[0] || "",
        batch_status!,
        shift_id || "",
        crane_id || "",
        start || "",
        end || ""
      ).then((res: any) => {
        if (res && res.dashboard) {
          setDashboardManager([...res.dashboard]);
          checkResourcesAvailability(res.dashboard);
        } else {
          setDashboardManager([]);
        }
        setLoading(false);
      });
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const checkResourcesAvailability = async (dashboardItems: any[]) => {
    const availability: { [vessel: string]: boolean } = {};
    for (const item of dashboardItems) {
      if (item.vessel) {
        const res = await getResources((item.vessel).split(" - ")[0]);
        console.log(item.vessel, res);
        availability[item.vessel] = res.resources !== null;
        console.log(item.vessel, availability);
      }
    }
    setResourcesAvailability(availability);
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "10px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={
            batch_status === "true"
              ? `${t("openBatches")}`
              : `${t("closedBatches")}`
          }
          goBack={() =>
            navigate(
              dashboardManagerUrl +
                `?batch_status=${batch_status}&batch_id=${batch_id}&shift_id=${shift_id}&crane_id=${crane_id}&start=${start}&end=${end}`
            )
          }
        />
      </Grid>

      <Grid item xs={12}>
        <CustomCard
          content={
            <>
              {dashboard
                .filter((f) =>
                  Object.values(f).some((value) =>
                    value
                      ?.toString()
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                )
                .map(
                  (d) =>
                    d.vessel && (
                      <>
                        <CustomList
                          dense
                          listElement={[
                            {
                              primaryText: d.vessel.split(" - ")[0],
                              action: (
                                <Grid container spacing={0}>
                                  <Grid item xs>
                                    <CustomIconButton
                                      type="outlined"
                                      icon={<Search />}
                                      onClick={() =>
                                        navigate(
                                          getDashboardManagerResultDetailsUrl +
                                            `?batch_status=${batch_status}&batch_id=${d.vessel}&shift_id=${shift_id}&crane_id=${crane_id}&start=${start}&end=${end}`
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs>
                                    <CustomIconButton
                                      type="outlined"
                                      icon={<Handyman />}
                                      disabled={!resourcesAvailability[d.vessel]}
                                      onClick={() => 
                                        navigate(
                                          getDashboardManagerResultGearUrl +
                                            `?batch_status=${batch_status}&batch_id=${d.vessel}&shift_id=${shift_id}&crane_id=${crane_id}&start=${start}&end=${end}`
                                        )
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              ),
                            },
                          ]}
                        />
                        <CustomDivider />
                      </>
                    )
                )}
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export default DashboardManagerMobile;

/*
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "10px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={
            batch_status === "true"
              ? `${t("openBatches")}`
              : `${t("closedBatches")}`
          }
          goBack={() =>
            navigate(
              dashboardManagerUrl +
                `?batch_status=${batch_status}&batch_id=${batch_id}&shift_id=${shift_id}&crane_id=${crane_id}&start=${start}&end=${end}`
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CustomList dense listElement={[...filters]} />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          label={`${t("search")}`}
          value={search}
          handleChange={(e) => setSearch(e.target.value)}
          endAdornment={<SearchOutlined />}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomCard
          content={
            <>
              {dashboard
                .filter((f) => {
                  //filter based on search value (no case sensitive) on all fields
                  let found = false;
                  Object.keys(f).forEach((key) => {
                    if (
                      f[key] &&
                      f[key]
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      found = true;
                    }
                  });
                  return found;
                })
                .map((d, index) => {
                  let tmp = [];
                  if (d.vessel)
                    tmp.push({
                      primaryText: `${t("vessel")}`,
                      secondaryText: d.vessel,
                    });
                  if (d.num_dock)
                    tmp.push({
                      primaryText: `${t("numDocks")}`,
                      secondaryText: d.num_dock,
                    });
                  if (d.total_movements)
                    tmp.push({
                      primaryText: `${t("totalMovements")}`,
                      secondaryText: d.total_movements,
                    });
                  if (d.moves_done)
                    tmp.push({
                      primaryText: `${t("movesDone")}`,
                      secondaryText: d.moves_done,
                    });
                  if (d.moves_to_do)
                    tmp.push({
                      primaryText: `${t("movesToDo")}`,
                      secondaryText: d.moves_to_do,
                    });
                  if (d.percentage)
                    tmp.push({
                      primaryText: `${t("percentage")}`,
                      secondaryText: d.percentage + "%",
                    });
                  if (d.vessel_fl)
                    tmp.push({
                      primaryText: `${t("vesselFL")}`,
                      secondaryText:
                        d.vessel_fl !== "0001-01-01T00:00:00Z"
                          ? d.vessel_fl.split("T")[0] +
                            " " +
                            d.vessel_fl.split("T")[1].split("Z")[0]
                          : "N.A.",
                    });
                  if (d.vessel_ll)
                    tmp.push({
                      primaryText: `${t("vesselLL")}`,
                      secondaryText:
                        d.vessel_ll !== "0001-01-01T00:00:00Z"
                          ? d.vessel_ll.split("T")[0] +
                            " " +
                            d.vessel_ll.split("T")[1].split("Z")[0]
                          : "N.A.",
                    });
                  if (d.working_time)
                    tmp.push({
                      primaryText: `${t("workingTime")}`,
                      secondaryText: d.working_time,
                    });
                  if (d.gmph)
                    tmp.push({
                      primaryText: `${t("gmph")}`,
                      secondaryText: d.gmph,
                    });
                  return (
                    <>
                      <CustomList dense listElement={[...tmp]} />
                      <CustomDivider />
                    </>
                  );
                })}
            </>
          }
        />
      </Grid>
    </Grid>
  );
*/
