import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomList from "../../custom/CustomList";
import CustomDivider from "../../custom/CustomDivider";
import { getDashboardManagerResultGearDetailsUrl } from "../costants";
import { Link } from "react-router-dom";
import { getResources } from "../../../api/services/resourceService";
import CustomLoading from "../../custom/CustomLoading";
import empty from "../../../images/empty.svg";
import CustomText from "../../custom/CustomText";

type DashboardManagerMobileGearProps = {
  setNavbarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const DashboardManagerMobileGear: React.FC<DashboardManagerMobileGearProps> = ({
  setNavbarCollapsed,
  setHeaderCollapsed,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const batch_id = searchParams.get("batch_id");
  const batch_status = searchParams.get("batch_status");

  useEffect(() => {
    setNavbarCollapsed(true);
    setHeaderCollapsed(true);
    return () => {
      setHeaderCollapsed(false);
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const [resources, setResources] = useState<any[]>([]);
  useEffect(() => {
    getResources(batch_id?.split(" - ")[0] || "").then((res: any) => {
      if (res && res.resources) {
        setResources([...res.resources]);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "10px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("workWithGangs")}`}
          goBack={() => navigate(-1)}
        />
      </Grid>
      {resources && resources.length > 0 ? (
        resources.map((el) => (
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <CustomList
                dense
                listElement={[
                  {
                    primaryText: `${t("date")}`,
                    secondaryText: el?.assigned_at
                      ? new Date(el?.assigned_at)
                          .toISOString()
                          .split("T")[0]
                      : "N.A.",
                  },
                  {
                    primaryText: `${t("shift")}`,
                    secondaryText: el?.shift || "N.A.",
                  },
                  {
                    primaryText: `${t("crane")}`,
                    secondaryText: el?.crane || "N.A.",
                  },
                  {
                    primaryText: `${t("moves")}`,
                    secondaryText: el?.moves || "N.A.",
                    action: (
                      <Link
                        to={
                          getDashboardManagerResultGearDetailsUrl +
                          `?batch_id=${
                            batch_id?.split(" - ")[0] || ""
                          }&batch_status=${batch_status}&shift_id=${
                            el?.shift
                          }&crane_id=${el?.crane_id}&crane_driver=${
                            el?.crane_operator
                          }&date=${(el?.assigned_at).split("T")[0]}&controller=${el?.controller}`
                        }
                        style={{
                          paddingLeft: "16px",
                          color: "inherit",
                        }}
                      >
                        {t("details")}
                      </Link>
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomDivider />
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item container spacing={2} xs={12} textAlign="center">
          <Grid item xs={12}>
            <img
              src={empty}
              alt="empty"
              width="80%"
              style={{ marginLeft: "9%", marginRight: "9%", marginTop: "32px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomText label={`${t("noData")}`} type="h5" />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardManagerMobileGear;
