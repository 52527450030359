import { isDev } from "../../components/pages/costants";

// BASE URLS
export const backendUrl = window._env_.API_ENDPOINT!;
export const grafanaUrl = window._env_.GRAFANA_ENDPOINT!;
export const zdmUrl = isDev
  ? "https://sct.app.localhost/proxy"
  : `https://app.sct.zerynth.com/proxy`;

//LOGIN SERVICE
export const loginUrl = `${backendUrl}/auth/login`;
export const logoutUrl = `${backendUrl}/auth/logout`;
export const setNewPasswordUrl = `${backendUrl}/auth/recover/end`;

//USERS SERVICE
export const getUsersUrl = (type: "manager" | "controller" | "admin") =>
  `${backendUrl}/users/${type}`;
export const createUserUrl = `${backendUrl}/users`;
export const getProfileUrl = `${backendUrl}/auth/profile`;
export const getUserUrl = (uid: string) => `${backendUrl}/users/${uid}`;
export const updateUserUrl = (uid: string) => `${backendUrl}/users/${uid}`;
export const getCompanyByAdminUrl = (uid: string) =>
  `${backendUrl}/users/aziende?admin=${uid}`;
export const getAllUsersUrl = `${backendUrl}/users/list`;
export const deleteuserUrl = (uid: string) =>
  `${backendUrl}/users/delete/${uid}`;
export const resetPasswordUrl = (uid: string) =>
  `${backendUrl}/users/${uid}/reset`;

//ORDERS SERVICE
export const getOrdersUrl = `${backendUrl}/orders`;
export const getAllOrdersUrl = `${backendUrl}/orders/get/all/order/list`;
export const createOrderUrl = `${backendUrl}/orders`;
export const getOrderUrl = (oid: string) => `${backendUrl}/orders/${oid}`;
export const updateOrderUrl = (oid: string) =>
  `${backendUrl}/orders/update/${oid}`;
export const deleteOrderUrl = (oid: string) =>
  `${backendUrl}/orders/delete/${oid}`;
export const getResourcesUrl = (oid: string) =>
  `${backendUrl}/orders/${oid}/resources`;
export const getResourceUrl = (id: string) =>
  `${backendUrl}/orders/single/${id}`;
export const assignResourceUrl = `${backendUrl}/orders/resources/assign`;
export const getResourcesToAssignUrl = (oid: string | undefined) =>
  `${backendUrl}/orders/resources/${oid}/assign`;
export const updateResourceUrl = (oid: string) =>
  `${backendUrl}/orders/resources/update/${oid}`;
export const deleteResourceUrl = (oid: string) =>
  `${backendUrl}/orders/resources/delete/${oid}`;
export const getAvailableResourcesUrl = (order_id: string, date: string, shift: string) =>
  `${backendUrl}/orders/resources/available/list?order_id=${order_id}&date=${date}&shift=${shift}`;
export const getResourcesByDateAndShiftUrl = (oid: string, date: string, shift: string) =>
  `${backendUrl}/orders/resources/${oid}/assigned/list?date=${date}&shift=${shift}`;

// INFO MANAGEMENT SERVICE
export const infoManagementUrl = `${backendUrl}/info-management`;

// CRANE SERVICE
export const createCraneUrl = `${backendUrl}/users/cranes`;
export const getCraneUrl = (uid: string) => `${backendUrl}/users/cranes/${uid}`;
export const getCranesUrl = `${backendUrl}/users/cranes`;
export const updateCraneUrl = (uid: string) =>
  `${backendUrl}/users/cranes/update/${uid}`;
export const deleteCraneUrl = (uid: string) =>
  `${backendUrl}/users/cranes/delete/${uid}`;

// CRANE OPERATOR SERVICE
export const createCraneOperatorUrl = `${backendUrl}/users/cranes-operators`;
export const getCraneOperatorUrl = (uid: string) =>
  `${backendUrl}/users/cranes-operators/${uid}`;
export const getCraneOperatorsUrl = `${backendUrl}/users/cranes-operators`;
export const updateCraneOperatorUrl = (uid: string) =>
  `${backendUrl}/users/cranes-operators/update/${uid}`;
export const deleteCraneOperatorUrl = (uid: string) =>
  `${backendUrl}/users/cranes-operators/delete/${uid}`;

// DOCK SERVICE
export const createDockModeUrl = `${backendUrl}/users/docks/modes`;
export const createDockNumUrl = `${backendUrl}/users/docks/numbers`;
export const getDockModeUrl = (uid: string) =>
  `${backendUrl}/users/docks/modes/${uid}`;
export const getDockNumUrl = (uid: string) =>
  `${backendUrl}/users/docks/numbers/${uid}`;
export const getDockModesUrl = `${backendUrl}/users/docks/modes`;
export const getDockNumbersUrl = `${backendUrl}/users/docks/numbers`;
export const getDockInfoUrl = `${backendUrl}/users/docks_info`;
export const updateDockModeUrl = (uid: string) =>
  `${backendUrl}/users/docks/modes/update/${uid}`;
export const updateDockNumUrl = (uid: string) =>
  `${backendUrl}/users/docks/numbers/update/${uid}`;
export const deleteDockModeUrl = (uid: string) =>
  `${backendUrl}/users/docks/modes/delete/${uid}`;
export const deleteDockNumUrl = (uid: string) =>
  `${backendUrl}/users/docks/numbers/delete/${uid}`;

// SIGNALERS SERVICE
export const createSignalerUrl = `${backendUrl}/users/signalers`;
export const getSignalerUrl = (uid: string) =>
  `${backendUrl}/users/signalers/${uid}`;
export const getSignalersUrl = `${backendUrl}/users/signalers`;
export const updateSignalerUrl = (uid: string) =>
  `${backendUrl}/users/signalers/update/${uid}`;
export const deleteSignalerUrl = (uid: string) =>
  `${backendUrl}/users/signalers/delete/${uid}`;

// DASHBOARD SERVICE
export const getDashboardControllerUrl = (
  batch_id: string,
  shift_id: string,
  crane_id: string,
  date: string
) =>
  `${backendUrl}/orders/dashboard-controller?batch_id=${batch_id}&shift_id=${shift_id}&crane_id=${crane_id}&date=${date}`;
export const getDashboardManagerUrl = (
  batch_status: string,
  batch_id: string,
  shift_id: string,
  crane_id: string,
  start: string,
  end: string
) =>
  `${backendUrl}/orders/dashboard-manager?batch_status=${batch_status}&batch_id=${batch_id}&shift_id=${shift_id}&crane_id=${crane_id}&start=${start}&end=${end}`;

// SETTINGS SERVICE
export const getSettingsUrl = `${backendUrl}/users/admin/settings`;
export const updateSettingsUrl = `${backendUrl}/users/admin/settings`;
