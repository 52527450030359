import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDashboardControllerUrl, mainUrl } from "../costants";
import { getResources } from "../../../api/services/resourceService";
import CustomLoading from "../../custom/CustomLoading";
import CustomIconButton from "../../custom/CustomIconButton";
import Tooltip from "@mui/material/Tooltip";
import BarChartIcon from "@mui/icons-material/BarChart";

type AssignedResourcesManagerProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const AssignedResourcesManager: React.FC<AssignedResourcesManagerProps> = ({
  setBreadcrumb,
  title,
}) => {
  const { id } = useParams(); //order uid
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [resources, setResources] = useState<any[]>([]);
  useEffect(() => {
    getResources(id!).then((res: any) => {
      if (res && res.resources) {
        setResources([...res.resources]);
      } else {
        setResources([]);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("order") + " " + id + ": " + t(title)}
          goBack={() => navigate(-1)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("assignment")}`,
              field: "assigned",
              description: `${t("assignment")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{params.row.assigned_at.split("T")[0]}</>
              ),
            },
            {
              headerName: `${t("shift")}`,
              field: "shift",
              description: `${t("shift")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{t("shift") + " " + params.row.shift}</>
              ),
            },
            {
              headerName: `${t("crane")}`,
              field: "crane",
              description: `${t("crane")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{params.row.crane}</>
              ),
            },
            {
              headerName: `${t("craneOperator")}`,
              field: "craneOperator",
              description: `${t("craneOperator")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{params.row.crane_operator}</>
              ),
            },
            {
              headerName: `${t("signaler")}`,
              field: "signaler",
              description: `${t("signaler")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{params.row.signaler}</>
              ),
            },
            {
              headerName: `${t("controller")}`,
              field: "controller",
              description: `${t("controller")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{params.row.controller}</>
              ),
            },
            {
              headerName: `${t("created")}`,
              field: "created_at",
              description: `${t("created")}`,
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.created_at.split("T")[0] +
                    " " +
                    params.row.created_at.split("T")[1].split(".")[0]}
                </>
              ),
            },
            {
              headerName: "",
              field: "",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  <a
                    href={
                      getDashboardControllerUrl(
                        id!,
                        params.row.shift,
                        params.row.crane,
                        params.row.assigned_at.split("T")[0],
                        true
                      ) + "&manager=true"
                    }
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <CustomIconButton
                      type="outlined"
                      icon={
                        <Tooltip title={t("seeDashboard")}>
                          <BarChartIcon />
                        </Tooltip>
                      }
                      onClick={() => {
                        /*
                        navigate(
                          getDashboardControllerUrl(
                            id!,
                            params.row.shift,
                            params.row.crane,
                            params.row.assigned_at.split("T")[0],
                            true
                          )
                        );
                      */
                      }}
                    />
                  </a>
                </>
              ),
            },
          ]}
          rows={resources}
        />
      </Grid>
    </Grid>
  );
};

export default AssignedResourcesManager;
